import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import PicksTableProps from './PicksTableProps';

type DesktopStandingsTableProps = {
  currentWeek: number;
  lastWeek: number;
  startWeek: number;
  weekPickUsers: PicksTableProps['weekPickUsers'];
};

function DesktopStandingsTable({ currentWeek, lastWeek, startWeek, weekPickUsers }: DesktopStandingsTableProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell sx={{ textAlign: 'center' }}>Total</TableCell>
          {Array.from(Array(currentWeek + 1).keys())
            .slice(startWeek)
            .map(i => (
              <TableCell key={i} sx={{ textAlign: 'center' }}>
                {i === lastWeek ? 'Playoffs' : <>Week {i}</>}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {weekPickUsers.map(user => (
          <TableRow key={user.id}>
            <TableCell>{user.fullName}</TableCell>
            <TableCell sx={{ textAlign: 'center' }}>{user.totalScore}</TableCell>
            {user.weekScores.slice(startWeek).map((weekScore, i) => (
              <TableCell key={i} sx={{ textAlign: 'center' }}>
                {weekScore}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default DesktopStandingsTable;

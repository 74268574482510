import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import PicksTableProps from './PicksTableProps';

type MobileStandingsTableProps = {
  currentWeek: number;
  lastWeek: number;
  startWeek: number;
  weekPickUsers: PicksTableProps['weekPickUsers'];
};

function MobileStandingsTable({ currentWeek, lastWeek, startWeek, weekPickUsers }: MobileStandingsTableProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell sx={{ textAlign: 'center' }}>Total</TableCell>
          {startWeek <= currentWeek && (
            <TableCell sx={{ textAlign: 'center' }}>
              {currentWeek === lastWeek ? 'Playoffs' : <>Week {currentWeek}</>}
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {weekPickUsers.map(user => (
          <TableRow key={user.id}>
            <TableCell>{user.fullName}</TableCell>
            <TableCell sx={{ textAlign: 'center' }}>{user.totalScore}</TableCell>
            {startWeek <= currentWeek && (
              <TableCell sx={{ textAlign: 'center' }}>{user.weekScores[currentWeek]}</TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default MobileStandingsTable;

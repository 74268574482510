import React, { useCallback, useContext, useMemo, useState } from 'react';
import { authExchange } from '@urql/exchange-auth';
import { useNavigate } from 'react-router-dom';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import {
  cacheExchange,
  createClient,
  dedupExchange,
  fetchExchange,
  makeOperation,
  Provider as UrqlProvider,
  subscriptionExchange,
} from 'urql';
import UnauthApp from '../components/UnauthApp';
import { buildUrl } from '../utils';

function isTokenValid(token: string) {
  if (!token) {
    return false;
  }
  const decodedToken = JSON.parse(atob(token.split('.')[1]));
  const expDate = new Date(0);
  expDate.setUTCMilliseconds(decodedToken.exp * 1000);
  return expDate > new Date();
}

type AccountContextValue = {
  refreshToken: string;
  userId?: string;
  logout(): void;
  setRefreshToken(token: string): void;
};

const AccountContext = React.createContext<AccountContextValue>({
  refreshToken: null,
  userId: null,
  logout: () => null,
  setRefreshToken: () => null,
});

type AccountProviderProps = {
  children: React.ReactNode;
};

function AccountProvider({ children }: AccountProviderProps) {
  const [refreshToken, setRefreshTokenState] = useState(() => {
    const token = localStorage.getItem('refreshToken');
    return isTokenValid(token) ? token : null;
  });
  const setRefreshToken = useCallback(
    (token: string) => {
      localStorage.setItem('refreshToken', token);
      setRefreshTokenState(token);
    },
    [setRefreshTokenState],
  );
  const navigate = useNavigate();
  const logout = useCallback(() => {
    localStorage.removeItem('refreshToken');
    setRefreshTokenState(null);
    navigate('/');
  }, [setRefreshTokenState]);
  const userId = useMemo(() => {
    if (!refreshToken) {
      return null;
    }
    return JSON.parse(atob(refreshToken.split('.')[1])).user_id as string;
  }, [refreshToken]);
  const urqlClient = useMemo(() => {
    if (!refreshToken) {
      return null;
    }
    const subscriptionClient = new SubscriptionClient(buildUrl('/subscriptions', process.env.REACT_APP_WS_URL), {
      reconnect: true,
    });
    return createClient({
      url: buildUrl('/api/graphql'),
      exchanges: [
        dedupExchange,
        cacheExchange,
        authExchange({
          getAuth: async ({ authState }: { authState: { token: string } }) => {
            if (!isTokenValid(authState?.token)) {
              const response = await fetch(buildUrl('/api/token/refresh'), {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({ refresh: refreshToken }),
              });
              if (response.status === 200) {
                const responseData = await response.json();
                return { token: responseData.access };
              }
              return null;
            }
            return null;
          },
          addAuthToOperation: ({ authState, operation }) => {
            if (!isTokenValid(authState?.token)) {
              return operation;
            }
            const fetchOptions =
              typeof operation.context.fetchOptions === 'function'
                ? operation.context.fetchOptions()
                : operation.context.fetchOptions || {};
            return makeOperation(operation.kind, operation, {
              ...operation.context,
              fetchOptions: {
                ...fetchOptions,
                headers: {
                  ...fetchOptions.headers,
                  Authorization: `Bearer ${authState.token}`,
                },
              },
            });
          },
          willAuthError: ({ authState }) => !isTokenValid(authState?.token),
          didAuthError: ({ error }) => error.graphQLErrors.some(e => e.message === 'Permission denied'),
        }),
        fetchExchange,
        subscriptionExchange({
          forwardSubscription: operation => {
            return subscriptionClient.request(operation);
          },
        }),
      ],
    });
  }, [refreshToken]);

  return (
    <AccountContext.Provider value={{ refreshToken, userId, logout, setRefreshToken }}>
      {refreshToken ? <UrqlProvider value={urqlClient}>{children}</UrqlProvider> : <UnauthApp />}
    </AccountContext.Provider>
  );
}

export function useAccountContext() {
  return useContext(AccountContext);
}

export default AccountProvider;

import React, { useState } from 'react';
import { AppBar, Container, Paper, styled, Toolbar, Typography } from '@mui/material';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import EmailVerification from '../EmailVerification';
import CreateAccountForm from './CreateAccountForm';
import LoginForm from './LoginForm';

const AppContent = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(7),
  padding: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
  },
}));

const Background = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

function UnauthApp() {
  const [urlSearchParams] = useSearchParams();
  const token = urlSearchParams.get('token');
  const email = urlSearchParams.get('email');
  const [showLoginForm, setShowLoginForm] = useState(!token);
  return (
    <Routes>
      <Route
        path="/verify-email"
        element={
          <>
            <AppBar position="fixed">
              <Toolbar>
                <Typography variant="h5" sx={{ flexGrow: 1 }}>
                  College Pick Em 2024
                </Typography>
              </Toolbar>
            </AppBar>
            <AppContent>
              <EmailVerification />
            </AppContent>
          </>
        }
      />
      <Route
        path="*"
        element={
          <Background>
            <Container
              maxWidth="sm"
              sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Paper sx={{ p: 2 }}>
                {showLoginForm ? (
                  <LoginForm onSwitchForm={() => setShowLoginForm(false)} />
                ) : (
                  <CreateAccountForm
                    defaultEmail={email}
                    verificationToken={token}
                    onSwitchForm={() => setShowLoginForm(true)}
                  />
                )}
              </Paper>
            </Container>
          </Background>
        }
      />
    </Routes>
  );
}

export default UnauthApp;

import React from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useAccountContext } from '../../contexts/account';
import { useFlashMessageContext } from '../../contexts/flashMessage';
import { buildUrl } from '../../utils';
import InputField from '../forms/InputField';

type LoginFormProps = {
  onSwitchForm: () => void;
};

function LoginForm({ onSwitchForm }: LoginFormProps) {
  const { setRefreshToken } = useAccountContext();
  const { addFlashMessage, clearFlashMessage } = useFlashMessageContext();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  return (
    <form
      onSubmit={handleSubmit(({ email, password }) => {
        clearFlashMessage();
        return fetch(buildUrl('/api/token'), {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify({ email, password }),
        }).then(response => {
          if (response.status === 200) {
            return response.json().then(responseData => {
              setRefreshToken(responseData.refresh);
            });
          }
          if (response.status === 401) {
            return response.json().then(responseData => addFlashMessage('warning', responseData.detail));
          }
          addFlashMessage('error', 'An unknown error occurred');
        });
      })}
    >
      <Grid container spacing={2} sx={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <Typography variant="h3">College Pick Em 2024</Typography>
        </Grid>
        <Grid item xs={12}>
          <InputField
            control={control}
            label="Email"
            name="email"
            rules={{ required: 'Required' }}
            sx={{ width: '100%' }}
            type="email"
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            control={control}
            label="Password"
            name="password"
            rules={{ required: 'Required' }}
            sx={{ width: '100%' }}
            type="password"
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={onSwitchForm}>No Account? Create One</Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            {isSubmitting ? <CircularProgress size={24} /> : 'Login'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default LoginForm;

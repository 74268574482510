import React from 'react';
import { Box, Link, List as MuiList, ListItemText, ListProps, Typography } from '@mui/material';

function List({ children, sx, ...rest }: ListProps) {
  return (
    <MuiList
      sx={{
        listStyle: 'decimal',
        '& .MuiListItemText-root': {
          display: 'list-item',
          ml: 4,
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </MuiList>
  );
}

export default function Rules() {
  return (
    <Box sx={{ maxWidth: 1200, mt: 2 }}>
      <Typography variant="h6">League Rules</Typography>
      <List>
        <ListItemText>Each player will buy in with a league entry fee of $20.</ListItemText>
        <ListItemText>
          Players will start with a fixed balance of $1000. This won&apos;t correspond directly to real money.
        </ListItemText>
        <ListItemText>During each week, the market will be open for teams to be bought and sold.</ListItemText>
        <ListItemText>
          Before Noon on Saturday, August 31, teams can be bought in the initial offering. These prices will be
          advantageous compared to prices after games have been played.
        </ListItemText>
        <ListItemText>
          The market will close and transactions cannot be made during each weekend. The market will close at Noon on
          Saturday each weekend, and open at Noon on Monday after each weekend (Tuesday instead following Labor Day
          Weekend). During open market periods, when teams play before Saturday, transactions for a that team will not
          be allowed after their game kicks off.
        </ListItemText>
        <ListItemText>
          Each FBS team will have a price per share and shares of that team can be freely bought and sold by players
          while the market is open. There is no limit to the number of shares of a team that can be owned in the league.
        </ListItemText>
        <ListItemText>
          Teams will be priced using a custom formula that responds to market activity within the league. More on this
          pricing formula below.
        </ListItemText>
        <ListItemText>
          Dividends will be paid to players&apos; available balances based on the accomplishments of teams whose shares
          they own. These accomplishments will include wins and postseason accolades. More on these dividend payouts
          below.
        </ListItemText>
        <ListItemText>
          The market will open after the last regular season games, and close for good before conference championships
          and bowl games.
        </ListItemText>
        <ListItemText>
          At the end of the season, each player&apos;s total portfolio value will be calculated, and each player will
          receive cash winnings proportional to their share of the league&apos;s total portfolio value. Example below.
        </ListItemText>
      </List>

      <Typography variant="h6" sx={{ mt: 2 }}>
        Pricing Formula
      </Typography>
      <List>
        <ListItemText>
          Team prices are based on the Sagarin computer rating system, which can be found at{' '}
          <Link href="http://sagarin.com/sports/cfsend.htm" target="_blank">
            http://sagarin.com/sports/cfsend.htm
          </Link>
          . These ratings are updated weekly, and the prices will be updated weekly by applying the following
          calculations to that week&apos;s new Sagarin ratings.
        </ListItemText>
        <ListItemText>
          The Sagarin rating will first be cubed (rating ^ 3). Cubing the rating relatively increases the spread between
          teams with higher ratings, and relatively decreases the spread between teams with lower ratings.
        </ListItemText>
        <ListItemText>
          Next, to calculate the rating price, the rating will be normalized to fit the desired minimum team share price
          ($5.00) and maximum team share price ($100.00).
        </ListItemText>
        <ListItemText>
          To calculate the base price, the rating price will be increased by 3% per regular season win. For example, if
          halfway through the season a team has won 4 games, their base price will be (Rating Price * 1.12).
        </ListItemText>
        <ListItemText>
          To calculate the market purchase price, the base price will be exponentially increased by 1% for each share
          owned by players in the league. For example, if a team has 25 shares owned throughout the league, their market
          purchase price will be (Base Price * (1.01 ^ 25)).
        </ListItemText>
        <ListItemText>
          To calculate the market sale price, the base price will be exponentially increased by 1% for each share owned
          by other players in the league. For example, if a team has 25 shares owned throughout the league, but the
          player selling a share owns 10 of them, their market purchase price will be (Base Price * (1.01 ^ 15)). This
          will create a discrepancy between the purchase price and sale price that will function as a transaction fee,
          particularly for players that own a lot of shares of a given team.
        </ListItemText>
        <ListItemText>
          The initial offering prices will use Sagarin&apos;s initial ratings, calculated through step 2 and 3 above,
          but not including steps 4 through 6.
        </ListItemText>
      </List>

      <Typography variant="h6" sx={{ mt: 2 }}>
        Dividend Formula
      </Typography>
      <List>
        <ListItemText>
          When a team wins a regular season game, a dividend will be paid for each share of that team that is owned.
        </ListItemText>
        <ListItemText>
          The dividend will be 3% of the market purchase price for the team that they beat. No dividends will be paid
          for wins over non-FBS opponents.
        </ListItemText>
        <ListItemText>
          For example, if a player owns 5 shares of Team A, and in the regular season they beat Team B whose market
          share price was $75, that player will receive a dividend of (5 * 3% * $75 = $11.25).
        </ListItemText>
        <ListItemText>
          Postseason dividends will be paid as a percentage of the accomplishing team&apos;s final market purchase
          price.
        </ListItemText>
        <ListItemText>
          Postseason dividends will be paid to:
          <MuiList sx={{ listStyle: 'lower-alpha' }}>
            <ListItemText>Power 4 conference champions (6%)</ListItemText>
            <ListItemText>Group of 5 conference champions (3%)</ListItemText>
            <ListItemText>Non-Playoff bowl game winners (3%)</ListItemText>
            <ListItemText>Playoff participants (4%)</ListItemText>
            <ListItemText>Playoff top-4 seed (additional 4%)</ListItemText>
            <ListItemText>Each playoff win (additional 4%)</ListItemText>
          </MuiList>
        </ListItemText>
        <ListItemText>
          Dividends will be added to the player&apos;s available balance, and will not be automatically reinvested.
        </ListItemText>
      </List>

      <Typography variant="h6" sx={{ mt: 2 }}>
        Postseason Payout Calculation
      </Typography>
      <List>
        <ListItemText>
          Assume there are 10 players who buy in for $20 each, and receive $1000 each in play money. There is $200 in
          the total payout pool.
        </ListItemText>
        <ListItemText>
          At the end of the season, each player&apos;s portfolio value should have increased. The league average
          portfolio value may now be $1500 instead of $1000.
        </ListItemText>
        <ListItemText>
          The portfolio value for each player will be calculated by adding the player&apos;s available balance to the
          sum of the market <span style={{ fontWeight: 'bold' }}>purchase</span> price for all owned shares.
        </ListItemText>
        <ListItemText>
          If a given player has a $3000 portfolio value, and the league average is $1500, then the league total
          portfolio value would be $15000, and the player would hold $3000 of that, which is 20%.
        </ListItemText>
        <ListItemText>
          That player would therefore receive 20% of the total payout pool, which would be a $40 payout.
        </ListItemText>
        <ListItemText>
          Notice that payouts <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>do not</span> correlate
          to increases in a player&apos;s portfolio value. If you increase your portfolio value by 20%, but everybody
          else increases theirs by an average of 40%, you will lose money even though your portfolio gained 20%.
        </ListItemText>
      </List>
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAccountContext } from '../../contexts/account';
import { useFlashMessageContext } from '../../contexts/flashMessage';
import { buildUrl } from '../../utils';
import InputField from '../forms/InputField';

type CreateAccountFormProps = {
  defaultEmail?: string;
  verificationToken?: string;
  onSwitchForm: () => void;
};

function CreateAccountForm({ defaultEmail, verificationToken, onSwitchForm }: CreateAccountFormProps) {
  const { setRefreshToken } = useAccountContext();
  const { addFlashMessage, clearFlashMessage } = useFlashMessageContext();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      email: defaultEmail || '',
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirm: '',
    },
  });
  const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);
  const password = watch('password', '');
  useEffect(() => {
    if (formHasBeenSubmitted) {
      trigger('passwordConfirm');
    }
  }, [password, formHasBeenSubmitted]);

  return (
    <form
      onSubmit={handleSubmit(
        ({ email, firstName, lastName, password }) => {
          clearFlashMessage();
          setFormHasBeenSubmitted(true);
          return fetch(buildUrl('/api/create-account'), {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
              email,
              first_name: firstName,
              last_name: lastName,
              password,
              verification_token: verificationToken,
            }),
          })
            .then(response => {
              if (response.status === 200) {
                return response.json().then(responseData => {
                  setRefreshToken(responseData.refresh);
                  navigate('/');
                });
              }
              if (response.status === 400) {
                return response.json().then(responseData => addFlashMessage('warning', responseData.message));
              }
              addFlashMessage('error', 'An unknown error occurred');
            })
            .catch(() => addFlashMessage('error', 'An unknown error occurred'));
        },
        () => setFormHasBeenSubmitted(true),
      )}
    >
      <Grid container spacing={2} sx={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <Typography variant="h3">College Pick Em 2024</Typography>
        </Grid>
        <Grid item xs={12}>
          <InputField
            control={control}
            label="Email"
            name="email"
            rules={{ required: 'Required' }}
            sx={{ width: '100%' }}
            type="email"
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            control={control}
            label="First Name"
            name="firstName"
            rules={{ required: 'Required' }}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            control={control}
            label="Last Name"
            name="lastName"
            rules={{ required: 'Required' }}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            control={control}
            label="Password"
            name="password"
            rules={{
              required: 'Required',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters long',
              },
            }}
            sx={{ width: '100%' }}
            type="password"
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            control={control}
            label="Confirm Password"
            name="passwordConfirm"
            rules={{ validate: value => (value !== password ? 'Passwords do not match' : undefined) }}
            sx={{ width: '100%' }}
            type="password"
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={onSwitchForm}>Already Have an Account? Login</Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            {isSubmitting ? <CircularProgress size={24} /> : 'Create Account'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default CreateAccountForm;

import React, { useState } from 'react';
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import { AppBar, Button, Divider, ListSubheader, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAccountContext } from '../../contexts/account';
import { FetchCurrentUserQuery } from '../../generated/hooks';

type AppHeaderProps = {
  currentUser: FetchCurrentUserQuery['currentUser'];
};

function AppHeader({ currentUser }: AppHeaderProps) {
  const { logout } = useAccountContext();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);

  return (
    <AppBar position="fixed">
      <Toolbar>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          College Pick Em 2024
        </Typography>
        {currentUser && (
          <>
            <Button
              sx={{ color: 'common.white', textTransform: 'inherit' }}
              onClick={e => setAnchorEl(e.currentTarget)}
            >
              <AccountCircleIcon sx={{ mr: 1 }} />
              <Typography variant="body1" sx={{ display: { xs: 'none', sm: 'block' } }}>
                {currentUser.fullName}
              </Typography>
            </Button>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <ListSubheader sx={{ display: { xs: 'list-item', sm: 'none' }, whiteSpace: 'nowrap' }}>
                Logged in as {currentUser.fullName}
              </ListSubheader>
              <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
              <MenuItem component={Link} to="/change-password">
                Change Password
              </MenuItem>
              <MenuItem
                onClick={() => {
                  logout();
                  setAnchorEl(null);
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default AppHeader;
